import React, { useEffect } from "react";
import { seo } from "../seo.js";
import { Form, Link } from "react-router-dom";
import Layout from "./layout.js";
import mapBackground from "../../src/images/map/map-bg.png";
import mapIcon from "../../src/images/map/map-icon.png";
import australia from "../../src/images/map/australia.png";
import srilanka from "../../src/images/map/sri-lanka.png";
import usa from "../../src/images/map/usa.png";
import brazil from "../../src/images/map/brazil.png";
import russia from "../../src/images/map/russia.png";
import canada from "../../src/images/map/canada.png";
import SouthAfrica from "../../src/images/map/south-africa.png";
import argentina from "../../src/images/map/argentina.png";
import colombia from "../../src/images/map/colombia.png";
import greenland from "../../src/images/map/greenland.png";
import japan from "../../src/images/map/japan.png";
import finland from "../../src/images/map/finland.png";
import china from "../../src/images/map/china.png";
import libya from "../../src/images/map/libya.png";
import gambia from "../../src/images/map/gambia.png";
import * as Constants from "../constants";

export default function WhereWeExport({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName="Where We Export">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-service py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Where We Export</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item"><span className="text-white">Export</span></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">Where We Export</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <section className="map-section">
        <div className="container-lg container-fluid">
          <div className="text-center mx-auto mb-sm-5 mb-3 wow fadeInUp" data-wow-delay="0.1s">
            <h4 className="display-7 text-primary section-title1 mb-3"><b>WANT TO KNOW ABOUT OUR EXPORT AREA?</b></h4>
          </div>
          <div className="map-content">
            <div className="img">
              {/* USA */}
              <div className="map-icon-div icon1 USA">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">USA</span>
              </div>
              {/* Greenland */}
              <div className="map-icon-div icon2 greenland">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Greenland</span>
              </div>
              {/* Gambia */}
              <div className="map-icon-div icon3 gambia">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Gambia</span>
              </div>
              {/* Libya */}
              <div className="map-icon-div icon4 libya">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Libya</span>
              </div>
              {/* China */}
              <div className="map-icon-div icon5 china">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">China</span>
              </div>
              {/* australia */}
              <div className="map-icon-div icon6 australia">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Australia</span>
              </div>
              {/* Japan */}
              <div className="map-icon-div icon7 Japan">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Japan</span>
              </div>
              {/* south-africa */}
              <div className="map-icon-div icon8 south-africa">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">South Africa</span>
              </div>
              {/* brazil */}
              <div className="map-icon-div icon9 brazil">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Brazil</span>
              </div>
              {/* Argentina */}
              <div className="map-icon-div icon10 argentina">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Argentina</span>
              </div>
              {/* Sri Lanka */}
              <div className="map-icon-div icon11 sri-lanka">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Sri Lanka</span>
              </div>
              {/* Finland */}
              <div className="map-icon-div icon12 finland">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Finland</span>
              </div>
              {/* Alaska */}
              <div className="map-icon-div icon13 Alaska">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Alaska</span>
              </div>
              {/* Russia */}
              <div className="map-icon-div icon14 russia">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Russia</span>
              </div>
              {/* Taiwan */}
              <div className="map-icon-div icon15 Taiwan">
                <img src={mapIcon} alt="Map icon" className="map-icon" />
                <span className="popup">Taiwan</span>
              </div>

              {/* Background Image  */}
              <img src={mapBackground} alt="Map" className="img-fluid main-map-bg" />
            </div>
          </div>

          <div className="country-cards">
            <div className="row country-cards-row">
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${australia})` }}>
                  <h4 className="country-name">Australia</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${srilanka})` }}>
                  <h4 className="country-name">Sri Lanka</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${usa})` }}>
                  <h4 className="country-name">USA</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${brazil})` }}>
                  <h4 className="country-name">Brazil</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col me-xl-1">
                <div className="country-card" style={{ backgroundImage: `url(${russia})` }}>
                  <h4 className="country-name">Russia</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${canada})` }}>
                  <h4 className="country-name">Alaska</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${SouthAfrica})` }}>
                  <h4 className="country-name">South Africa</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${argentina})` }}>
                  <h4 className="country-name">Argentina</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${colombia})` }}>
                  <h4 className="country-name">Taiwan</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col me-xl-1">
                <div className="country-card" style={{ backgroundImage: `url(${greenland})` }}>
                  <h4 className="country-name">Greenland</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${japan})` }}>
                  <h4 className="country-name">Japan</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${finland})` }}>
                  <h4 className="country-name">Finland</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${china})` }}>
                  <h4 className="country-name">China</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${libya})` }}>
                  <h4 className="country-name">Libya</h4>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3  col-md-4 col-6 country-card-col">
                <div className="country-card" style={{ backgroundImage: `url(${gambia})` }}>
                  <h4 className="country-name">Gambia</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
