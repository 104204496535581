import React, { useEffect } from "react";
import Layout from "./layout.js";
import { seo } from "../seo.js";
import { Link } from "react-router-dom";
import * as Constants from "../constants";

export default function PackingList({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName="Packing List">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-PackingList py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Packing List</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item"><span className="text-white">Export</span></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">Packing List</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <div className="container mb-4">
        <div className="text-center mx-auto mb-sm-3 mb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
          <h4 className="display-7 text-primary section-title1 mb-4"><b>EXPLORE OUR PACKING LIST</b></h4>
          <h4 className="display-8 mb-3 fs-16 text-primary">NORMAL PALLET PACKING 20' FCL</h4>
          <h6 className=" text-dark fs-16">GVT/PGVT (PORCELAIN GLAZED VITRIFIED TILES)</h6>
        </div>
        <div className="row">
          <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
            <div className="table-responsive">
              <table className=" text-center details-table bg-white table-sm table-bordered table-fs14 border-color" align="center">
                <thead className=" bg-primary text-white">
                  <tr>
                    <th>SIZE IN CM</th>
                    <th>THICKNESS</th>
                    <th>BOX/PCS</th>
                    <th>SQM/BOX</th>
                    <th>BOX/PALLET</th>
                    <th>PALLET/CONTAINER</th>
                    <th>BOX/CONTAINER</th>
                    <th>SQM/CONTAINER</th>
                    <th>APPROX WEIGHT/ CONTAINER</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>60X60</td>
                    <td>9</td>
                    <td>4</td>
                    <td>1.44</td>
                    <td>44</td>
                    <td>24</td>
                    <td>1056</td>
                    <td>1520.64</td>
                    <td>28000</td>
                  </tr>
                  <tr className="text-center">
                    <td rowSpan={2}>60X120</td>
                    <td rowSpan={2}>9</td>
                    <td rowSpan={2}>2</td>
                    <td rowSpan={2}>1.44</td>
                    <td>72</td>
                    <td>10</td>
                    <td rowSpan={2}>900</td>
                    <td rowSpan={2}>1296</td>
                    <td rowSpan={2}>28000</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>80X80</td>
                    <td>9</td>
                    <td>3</td>
                    <td>1.92</td>
                    <td>34</td>
                    <td>20</td>
                    <td>680</td>
                    <td>1305.6</td>
                    <td>28000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4">
        <div className="text-center mx-auto mb-sm-3 mb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
          <h4 className="display-8 mb-3 fs-16 text-primary">EURO PALLET PACKING 20' FCL</h4>
          <h6 className=" text-dark fs-16">GVT/PGVT (PORCELAIN GLAZED VITRIFIED TILES)</h6>
        </div>
        <div className="row">
          <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
            <div className="table-responsive">
              <table className=" text-center details-table bg-white table-sm table-bordered table-fs14 border-color" align="center">
                <thead className=" bg-primary text-white">
                  <tr>
                    <th>SIZE IN CM</th>
                    <th>THICKNESS</th>
                    <th>BOX/PCS</th>
                    <th>SQM/BOX</th>
                    <th>BOX/PALLET</th>
                    <th>PALLET/CONTAINER</th>
                    <th>BOX/CONTAINER</th>
                    <th>SQM/CONTAINER</th>
                    <th>APPROX WEIGHT/ CONTAINER</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>60X60</td>
                    <td>9</td>
                    <td>4</td>
                    <td>1.44</td>
                    <td>36</td>
                    <td>24</td>
                    <td>864</td>
                    <td>1244.16</td>
                    <td>24200</td>
                  </tr>
                  <tr>
                    <td>60X120</td>
                    <td>9</td>
                    <td>2</td>
                    <td>1.44</td>
                    <td>60</td>
                    <td>10</td>
                    <td>750</td>
                    <td>1080</td>
                    <td>24000</td>
                  </tr>
                  <tr>
                    <td>60X120</td>
                    <td>9</td>
                    <td>2</td>
                    <td>1.44</td>
                    <td>30</td>
                    <td>5</td>
                    <td>750</td>
                    <td>1080</td>
                    <td>24000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
