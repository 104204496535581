import React, { useEffect } from "react";
import { seo } from "../seo.js";
import Layout from "./layout.js";
import Labelling from "../images/labelling.png";
import CustomizedDesign from "../images/customized-design.png";
import Packing from "../images/packing.png";
import { Link } from "react-router-dom";
import * as Constants from "../constants";

export default function Service({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Layout pageName="Service">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-service py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Service</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item"><span className="text-white">Export</span></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">SERVICE</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <div className="text-center  wow fadeInUp">
        <h2 className="section-title1 heading-top-text position-relative"><b>WANT TO KNOW ABOUT OUR SERVICES?</b></h2>
      </div>
      <div className="container-xxl py-sm-5 py-3">
        <div className="container pt-5">
          <div className="row g-4 align-items-center justify-content-center facts-row">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon"><img src={Labelling} alt="Labelling" className="primary-filter" /></div>
                <h4 className="mb-3 "><b>Labelling</b></h4>
                <p className="mb-0">In recent time export client have requirement of there own brands. We manufacture superior quality tiles for that market with renowned brand name manufacturer by Velloza Granito LLP.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={CustomizedDesign} alt="Customized Design" className="primary-filter"/>
                </div>
                <h4 className="mb-3"><b>Customized Design</b></h4>
                <p className="mb-0">All regions have unique color combinations in their designs, and we are always eager to develop designs based on our customer's demands.</p>
                <br />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={Packing} alt="Packing" className="primary-filter" />
                </div>
                <h4 className="mb-3"><b>Packing</b></h4>
                <p className="mb-0">Packing is one of the keypart to reach safe material at end user. So we provide pallet packing as per specific country or customer's requirement.
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
