import React, { Component } from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import { Routes, Route, Link, BrowserRouter as Router } from "react-router-dom";
import logo from "./logo.svg";
import website_logo from "./logo.png";
import favicon from "./fav-icon.png";
import "./App.css";
import * as Constants from "./constants";
import useEffect from "react";
import PageNotFound from "./pages/404-error.js";
import Home from "./pages/home.js";
import Contact from "./pages/contact-us.js";
import CompanyProfile from "./pages/company-profile.js";
import Certificate from "./pages/certificate.js";
import PackingList from "./pages/packing-list.js";
import TechnicalSpecification from "./pages/technical-specification.js";
import Service from "./pages/service.js";
import WhereWeExport from "./pages/where-we-export.js";
import TilesCalculator from "./pages/tiles-calculator.js";
import Download from "./pages/download.js";
import "./css/main.css";
import "./css/style.css";
import CategoryInfo from "./pages/category-info.js";
import axios from "axios";

const doNothingOnClick = (e) => {
  e.preventDefault()
}

function App({ Component, pageProps }) {
  	
	return (
    <div className="App">
      <Routes>
      	<Route path={Constants.INDEX_PAGE_URL} element={<Home />} />
      	<Route path={Constants.HOME_PAGE_URL} element={<Home />} />
        <Route path='/category' element={<CategoryInfo />} />
        <Route path='/category/:category_slug' element={<CategoryInfo />} />
        <Route path={Constants.LANDING_PAGE_URL} element={<Home />} />
        <Route path={Constants.CONTACT_US_PAGE_URL} element={<Contact />} />
        <Route path={Constants.COMPANY_PROFILE_PAGE_URL} element={<CompanyProfile />}/>
        <Route path={Constants.CERTIFICATE_PAGE_URL} element={<Certificate />}/>
        <Route path={Constants.PACKING_LIST_PAGE_URL} element={<PackingList />}/>
        <Route path={Constants.TECHNICAL_SPECIFICATION_PAGE_URL} element={<TechnicalSpecification />}/>
        <Route path={Constants.SERVICE_PAGE_URL} element={<Service />} />
        <Route path={Constants.WHERE_WE_EXPORT_PAGE_URL} element={<WhereWeExport />}/>
        <Route path={Constants.TILES_CALCULATOR_PAGE_URL} element={<TilesCalculator />}/>
        <Route path={Constants.DOWNLOAD_PAGE_URL} element={<Download />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
