import React, {useEffect} from "react";
import { useState  } from "react";
import footer_logo from '../../src/images/footer-logo.png';

const Spinner = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading && (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div
            className="spinner-border position-relative text-primary"
            style={{ width: "6rem", height: "6rem" }}
            role="status"
          ></div>
          <img
            className="position-absolute top-50 start-50 translate-middle spinner-img"
            src={footer_logo}
            alt="Icon"
          />
        </div>
      )}
    </div>
  );
};

export default Spinner;
