import React, { useEffect } from "react";
import Layout from "./layout.js";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import aboutImage from "../images/about-2.jpg";
import factIcon1 from "../images/icons/icon-2.png";
import factIcon2 from "../images/icons/icon-3.png";
import mission from "../images/mission.png";
import vission from "../images/vission.png";
import { seo } from "../seo.js";

export default function CompanyProfile({ children }) {
	useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  

  return (
    <Layout pageName="Company Profile">
      {/* Page Header Start  */}
      <div className="container-fluid page-header-company py-sm-5 py-4 mb-sm-5 mb-3 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
        <div className="container py-5">
          <h1 className="display-5 text-white animated slideInDown">Company Profile</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white" href="#">Home</Link></li>
              <li className="breadcrumb-item"><span className="text-white">Corporate</span></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Company Profile</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End  */}

      <div className="container-xxl py-sm-5 py-3">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn padding-0-col" data-wow-delay="0.1s">
              <div style={{ objectFit: "contain" }}>
                <img className="img-fluid wow fadeInLeft home-about-img" src={aboutImage} alt="About Us Image" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight padding-0-col" data-wow-delay="0.5s">
              <h4 className="section-title1"><b>ABOUT US</b></h4>
              <p>Velloza Granito LLP is an Indian Manufacturer and a exporter of Vitrified / Porcelain tiles With the capacity of 18000 SQMTR/DAY with the latest and innovative machinery.</p>
              <p>The promoters of Velloza Granito have a vast experience of more than 20 years in tiles manufacturing unit, which make company in front foot in quality and design development.</p>
              <p>With high end robust machine and latest technologies in its manufacturing process velloza has been successfully catering to the unique demands of the export sector.</p>
              <p className="mb-0">We value our customer's enquiry a lot more , we make it a point to ensure that our buyer gets the full value of their money once they place order with us.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-sm-5 py-3">
        <div className="container pt-5">
          <div className="row g-4 align-items-center justify-content-center facts-row">
            <div className="col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0.1s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon"><img src={mission} alt="Our Mission" className="primary-filter" /></div>
                <h4 className="mb-3"><b> Our Mission</b></h4>
                <p className="mb-0">Manufacturing and deliver high quality and latest updated Design to customer. Customer Satisfaction with quality and service is our main motto. <br /><br /></p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.3s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon"><img src={vission} alt="Our Vision" className="primary-filter" /></div>
                <h4 className="mb-3"><b>Our Vision</b></h4>
                <p className="mb-0">Manufacturing and deliver updating technology and design with customer satisfaction that enhance beauty of construction across the world and become one of the popular company.</p>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  );
}
