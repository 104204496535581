import $ from "jquery/dist/jquery.js";

// Back to top button
$(window).scroll(function () {
  if ($(this).scrollTop() > 300) {
    $(".back-to-top").fadeIn("slow");
  } else {
    $(".back-to-top").fadeOut("slow");
  }
});

$(".back-to-top").click(function () {
  //$("html, body").animate({ scrollTop: 0 }, 200, "easeInOutExpo");
  $("html, body").animate({ scrollTop: 0 }, 200);
  return false;
});

// $(document).ready(function () {
//   // Prevent closing from click inside dropdown
//   $(".dropdown-menu").on("click", function (e) {
//     e.stopPropagation();
//   });

//   // make it as accordion for smaller screens
//   if ($(window).width() < 992) {
//     // close all inner dropdowns when parent is closed
//     $(".navbar .dropdown").on("hidden.bs.dropdown", function () {
//       // after dropdown is hidden, then find all submenus
//       $(this)
//         .find(".submenu")
//         .each(function () {
//           // hide every submenu as well
//           $(this).hide();
//         });
//     });

//     $(".dropdown-menu a").on("click", function (e) {
//       let nextEl = $(this).next();
//       if (nextEl && nextEl.hasClass("submenu")) {
//         // prevent opening link if link needs to open dropdown
//         e.preventDefault();
//         if (nextEl.is(":visible")) {
//           nextEl.hide();
//         } else {
//           nextEl.show();
//         }
//       }
//     });
//   }
// });

$(window).scroll(function() {
	if ($(this).scrollTop() > 72) {
		$(".twt-navbar").addClass("fixed");
	} else {
		$(".twt-navbar").removeClass("fixed");
	}
});