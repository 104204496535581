import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import $ from 'jquery';
import {decode} from 'html-entities';
import * as Constants from "../../constants";
import whatsapp_icon from "../../images/whatsapp.png";
import sales_icon from "../../images/call-center.png";
import hr_icon from "../../images/sales.svg";
import { ArrowUp } from "react-bootstrap-icons";
import "../../js/custom";
import "@flaticon/flaticon-uicons/css/all/all.css";

function checkName() {
  $("html, body").animate({ scrollTop: 0 }, 200);
}

export default function Footer({ settingInfo }) {
	
  let rendorComplete = settingInfo.i_id;
  const [isIconActive, setIsIconActive] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleIconClick = () => {
    setIsIconActive(!isIconActive);
    setIsPopupVisible(!isPopupVisible);
  };

  const handlePopupClick = () => {
    setIsPopupVisible(false);
  };

  const cssClasses = ["main-whatsaap-icon"];
  if (isIconActive) {
    cssClasses.push("wa__active");
  }

  return (
    <footer>
      <button
        type="button"
        onClick={checkName}
        className="rounded-0 btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <ArrowUp color="white" size={20} />
      </button>
      <div
        className="container-fluid bg-dark border-box text-body footer mt-sm-5 pt-sm-5 mt-3 pt-3 px-0 wow fadeIn footer"
        data-wow-delay="0.1s"
      >
        <div className="container py-4">
          <div className="row g-4">
            <div className="col-md-4">
              <h3 className="text-light  mb-1">
              {settingInfo.v_website_footer_logo != null && settingInfo.v_website_footer_logo != "" && (
                <Link to={Constants.HOME_PAGE_URL}>
                  <img src={Constants.BACKEND_FILE_URL + settingInfo.v_website_footer_logo} alt={settingInfo.v_site_title} className="footer-logo img-fluid" />
                </Link>
              )}
              </h3>
                {settingInfo.v_about_short_description !== null &&(
                		<p dangerouslySetInnerHTML={{__html: decode(settingInfo.v_about_short_description)}}></p>
                )}
            </div>
           
            <div className="col-lg-4">
           
              <h3 className="text-light mb-sm-4 mb-2">Address</h3>
              {settingInfo.v_address !== null &&(
              <span className="d-flex">
                <i className="fa fa-map-marker text-primary me-3 pt-1 footer-icon"></i>
                <p className="mb-2" dangerouslySetInnerHTML={{__html: decode(settingInfo.v_address)}}></p>
              </span>
              )}
              {settingInfo.v_primary_mobile_no !== null && settingInfo.v_primary_mobile_no !== "" && rendorComplete &&(
              <a href={"tel:" + settingInfo.v_primary_mobile_no.replace(/ /g,'')} className="mb-2 d-block">
                <i className="fa fa-phone text-primary me-3 footer-icon"></i>
                {settingInfo.v_primary_mobile_no}
              </a>
              )} 
            {settingInfo.v_email !== null && settingInfo.v_email !== "" &&(
              <a href={"mailto:" + settingInfo.v_email} className="mb-2">
                <i className="fa fa-envelope text-primary me-3"></i>
                {settingInfo.v_email}
              </a>
              )}
            </div>
            <div className="col-lg-2 ">
              <h3 className="text-light mb-sm-4 mb-2">Quick Links</h3>
              <Link
                className="btn text-body"
                to={Constants.COMPANY_PROFILE_PAGE_URL}
              >
                CORPORATE
              </Link>
              <Link className="btn text-body" to={Constants.DOWNLOAD_PAGE_URL}>
                E-CATALOGUE
              </Link>
              <Link
                className="btn text-body"
                to={Constants.CONTACT_US_PAGE_URL}
              >
                CONTACT US
              </Link>
            </div>
            <div className="col-lg-2">
              <h3 className="text-light mb-sm-4 mb-2">Follow Us</h3>
              <div className="d-flex pt-2">
              {settingInfo.v_facebook_link !== null &&(
                <a
                  className="btn btn-square btn-outline-body me-1 rounded-0"
                  target="_blank"
                  href={settingInfo.v_facebook_link}
                >
                  <i className="fa fa-facebook-f"></i>
                </a>
              )}
              {settingInfo.v_instagram_link !== null &&(
                <a
                  className="btn btn-square btn-outline-body me-1 rounded-0"
                  target="_blank"
                  href={settingInfo.v_instagram_link}
                >
                  <i className="fa fa-instagram"></i>
                </a>
              )}
              {settingInfo.v_linkedin_link !== null &&(
                <a
                  className="btn btn-square btn-outline-body me-1 rounded-0"
                  target="_blank"
                  href={settingInfo.v_linkedin_link}
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              )}
              {settingInfo.v_youtube_link !== null &&(
                <a
                  className="btn btn-square btn-outline-body me-1 rounded-0"
                  target="_blank"
                  href={settingInfo.v_youtube_link}
                >
                  <i className="fi fi-brands-youtube d-flex"></i>
                </a>
              )}
              {settingInfo.v_twitter_link !== null && (
                <a
                  className="btn btn-square btn-outline-body me-0 rounded-0"
                  target="_blank"
                  href={settingInfo.v_twitter_link}
                >
                  <i className="fi fi-brands-twitter d-flex"></i>
                </a>
              )}
              </div>
            </div>
          </div>
        </div>
        {settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" && (
          <div className="container-fluid copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-white text-center text-md-start mb-0 mb-md-0 d-flex">
                  &copy;{" "}
                  <p className="mb-0">{(new Date().getFullYear())} {settingInfo.v_site_title} All Rights Reserved. </p>
                </div>
                {settingInfo.v_powered_by !== null && settingInfo.v_powered_by !== "" && (
                  <div className="col-md-6 text-white text-right text-md-start mb-0 mb-md-0 d-flex justify-content-end">
                    <p className="mb-0">Developed By <a href={settingInfo.v_powered_by_link !== null && settingInfo.v_powered_by_link !== "" ? settingInfo.v_powered_by_link : void(0)} target="_blank" >{settingInfo.v_powered_by}</a>.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
        

      <div className={settingInfo.e_whatsapp_position !== null && settingInfo.e_whatsapp_position !== "" ? settingInfo.e_whatsapp_position : 'right' }>
            <div className="footer-fixed-bottom">
              <div className="footer-bottom-row">
                <div className="footer-bottom-col">
                  <button
                    type="button"
                    className={cssClasses.join(" ")}
                    onClick={handleIconClick}
                  >
                    <div className="main-whatsaap-icon">
                      <div className="bg-image"></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            {isPopupVisible && (
              <div className="main-box-pop">
                <div className="card main-card">
                  <div className="card-header custom-card-header bg-whatsapp">
                    <div className="media">
                      <img
                        src={whatsapp_icon}
                        alt="whatsapp"
                        className="whatsapp-img mr-3"
                      />
                      <div className="media-body">
                        <h5 className="mt-0 h5 text-white mb-1 header-heading">
                          Start a Conversation
                        </h5>
                        <p className="text-white mb-0 text-whatsapp">
                          <small>
                            Hi! Click one of our member below to chat on Whatsapp
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-3">
                    <small className="text-muted d-block mb-3 text-whatsapp">
                      The team typically replies in a few minutes.
                    </small>
                    {settingInfo.v_whatsapp_number !== null && settingInfo.v_whatsapp_number !== "" && rendorComplete &&(
                      <a
                      href={"https://api.whatsapp.com/send?phone=" + settingInfo.v_whatsapp_number.replace(/[^0-9]/g, '')  }
                      target="_blank"
                      className="d-block link-unstyled"
                      >
                        <div className="card custom-card-inner-box animated border-0 shadow-sm mb-lg-3 mb-2">
                          <div className="card-body py-2">
                            <div className="media">
                              <img
                                src={sales_icon}
                                alt="defaultuser"
                                className="user-img mr-3"
                              />
                              <div className="media-body">
                                <h5 className="mt-0 h5 text-dark h6 mb-1 whatsaap-label">
                                  International
                                </h5>
                              </div>
                            </div>
                            <div className="overlay-icon">
                              <i className="fa fa-whatsapp"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    )}
                    {settingInfo.v_domenstic_whatsapp_no !== null && settingInfo.v_domenstic_whatsapp_no !== "" && rendorComplete &&(
                      <a
                      href={"https://api.whatsapp.com/send?phone=" + settingInfo.v_domenstic_whatsapp_no.replace(/[^0-9]/g, '') }
                      target="_blank"
                      className="d-block link-unstyled"
                      >
                        <div className="card custom-card-inner-box animated border-0 shadow-sm mb-lg-3 mb-2">
                          <div className="card-body py-2">
                            <div className="media">
                              <img
                                src={hr_icon}
                                alt="defaultuser"
                                className="user-img mr-3"
                              />
                              <div className="media-body">
                                <h5 className="mt-0 h5 text-dark h6 mb-1 whatsaap-label">
                                  Domestic
                                </h5>
                              </div>
                            </div>
                            <div className="overlay-icon">
                              <i className="fa fa-whatsapp"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
      </div>
    </footer>
  );
}
