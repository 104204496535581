import React, { useRef, useEffect, useState } from "react";
import { seo } from "../seo.js";
import { Form } from "react-router-dom";
import Layout from "./layout.js";
import { tilesCalculate } from "../js/tiles-calculate";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
tilesCalculate();

export default function TilesCalculator({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const preventMinus = (e) => {
      if (e.key === '-' || e.key === '+' || e.key === ' ') {
          e.preventDefault();
      }
    };

    const [lenghtInputValue, setlenghtInputValue] = useState("");
    const [WidthInputValue, setWidthInputValue] = useState("");

    const lenghtHandleInputChange = (e) => {
      const value = e.target.value;
      const numericValue = value.trim().replace(/[^ +\d]/g, "");
      setlenghtInputValue(numericValue);
    };

    const widthHandleInputChange = (e) => {
      const value = e.target.value;
      const numericValue = value.trim().replace(/[^ +\d]/g, "");
      setWidthInputValue(numericValue);
    };

  // for reset button
  const inputRef = useRef();
  const inputRef2 = useRef();
  const handleReset = () => {
    inputRef.current.value = "";
    inputRef2.current.value = "";
  };

  return (
    <Layout pageName="Tiles Calculator">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-technical py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Tiles Calculator</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">Tiles Calculator</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <main>
        <section className="px-8 sm:max-w-4xl mx-auto">
          <p className="text-md sm:w-max mx-auto relative text-center before:content-[''] before:block before:absolute before:h-[2px] before:w-12 before:bg-gray-900 before:bottom-0 sm:before:translate-x-[-120%]">Calculate as per your requirement</p>
          <h3 className="section-title1 text-2xl my-4 sm:text-4xl w-full text-center font-extrabold"><b> HOW MANY TILES DO I NEED?</b></h3>
          <p className="text-center">Calculate the required tiles & required box you need by entering the dimensions of your wall and/or floor to be covered into our tiles calculator below.*</p>
        </section>
        <section className="flex gap-4 my-8 px-sm-8 sm:max-w-4xl mx-auto table-responsive flex-wrap overflow-hidden">
          <div id="tiles-calculate" className="flex-[2] bg-dark text-white py-4 px-8 ">
            <p className="uppercase text-lg text-center my-4">YOUR REQUIREMENTS</p>
            <p className="text-lg my-2">Enter Measurements</p>
            <div className="my-4">
              <div className="flex gap-4">
                <div>
                  <label htmlFor="length">Length (m)</label>
                  <input type="number" name="length_m" min={0} onKeyPress={preventMinus} value={lenghtInputValue}  onChange={lenghtHandleInputChange} ref={inputRef} id="length" placeholder="Length (m)" required className="w-full border text-black border-gray-900 rounded-md my-2 px-4 py-2"/>
                </div>
                <div>
                  <label htmlFor="length">Width (m)</label>
                  <input type="number" name="width_m" min={0} onKeyPress={preventMinus} value={WidthInputValue}  onChange={widthHandleInputChange} ref={inputRef2} id="width" placeholder="Width (m)" required className="w-full border text-black border-gray-900 rounded-md my-2 px-4 py-2"/>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="height">Select Tiles Size (m)</label>
              <select name="select_tiles_size" required className="w-full border text-black border-gray-900 rounded-md my-2 px-4 py-2">
                <option className="text-black" value="600X600" sqmtr-pcs="0.36" data-per-pcs-value="4">600X600 MM ( Polished Glazed Porcelain Tiles )</option>
                <option className="text-black" value="600X1200" sqmtr-pcs="0.72" data-per-pcs-value="2">600X1200 MM ( Polished Glazed Porcelain Tiles )</option>
                <option className="text-black"  value="800X800"  sqmtr-pcs="0.64"  data-per-pcs-value="3">800X800 MM ( Polished Glazed Porcelain Tiles )</option>
                <option className="text-black" value="1600X800" sqmtr-pcs="1.28" data-per-pcs-value="2">1600X800 MM ( Polished Glazed Porcelain Tiles )</option>
              </select>
            </div>
            <div className="flex gap-8 justify-center my-4">
              <button type="button" onClick={tilesCalculate} className="px-6 py-3 bg-white text-dark rounded">Calculate</button>
              <button type="button" onClick={handleReset} className="px-6 py-3 bg-white text-dark rounded">Reset</button>
            </div>
          </div>
          <div className="flex-1 bg-dark text-center text-white py-4 px-8">
            <div className="border-b border-gray-700">
              <p className="uppercase text-lg my-4">YOUR RESULTS</p>
              <p className="my-2">You Require</p>
            </div>
            <div className="border-b border-gray-700">
              <p className="text-2xl my-2 font-extrabold no-of-titles"></p>
              <p className="my-2">No. of Tiles</p>
            </div>
            <div className="border-b border-gray-700">
              <p className="text-2xl my-2 font-extrabold no-of-boxes"></p>
              <p className="my-2">No. of Boxes</p>
            </div>
            <div className="border-b border-gray-700">
              <p className="text-2xl my-2 font-extrabold area-covered"></p>
              <p className="my-2">Area Covered</p>
            </div>
          </div>
        </section>
        <p className="px-8 text-sm mx-auto sm:max-w-4xl">* This may vary on basis of your actual need. This is only approx calculation.</p>
      </main>
    </Layout>
  );
}
