export const ABOUT_US_PAGE_URL = "/about-us.html";
export const CONTACT_US_PAGE_URL = "/contact-us.html";
export const HOME_PAGE_URL = "/velloza.html";
export const LOGIN_PAGE_URL = "/login";
export const LANDING_PAGE_URL = "/";
export const INDEX_PAGE_URL = "/index.html";
export const API_BASE_URL = "https://www.vellozagranito.com/backend/api/";
export const CHECK_LOGIN_API = "check-login.php";
export const SUCCESS_API_CALL_CODE = 200;
export const SUCCESS_API_CALL_STATUS_CODE = 1;
export const ERROR_API_CALL_STATUS_CODE = 101;
export const COMPANY_PROFILE_PAGE_URL = "/company-profile.html";
export const CERTIFICATE_PAGE_URL = "/certificate.html";
export const PACKING_LIST_PAGE_URL = "/packing-list.html";
export const TECHNICAL_SPECIFICATION_PAGE_URL = "/technical-specification.html";
export const SERVICE_PAGE_URL = "/service.html";
export const WHERE_WE_EXPORT_PAGE_URL = "/where-we-export.html";
export const GLOSSY_600_1200_PAGE_URL = "/600X1200-glossy";
export const TILES_CALCULATOR_PAGE_URL = "/tiles-calculator.html";
export const DOWNLOAD_PAGE_URL = "/download.html";
export const CATEGORY_INFO_PAGE_SLUF = "/category/";
export const BACKEND_FILE_URL = "https://www.vellozagranito.com/backend/uploads/";
export const SITE_URL = "https://www.vellozagranito.com/";
