import React, { useEffect } from "react";
import Layout from "./layout.js";
import { seo } from "../seo.js";
import { Link } from "react-router-dom";
import * as Constants from "../constants";

export default function TechnicalSpecification({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName="Technical Specification">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-technical py-sm-5 py-4 mb-sm-5 mb-3 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Technical Specification</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item"><span className="text-white" href="#">Export</span> </li>
                <li className="breadcrumb-item text-primary active" aria-current="page">TECHNICAL SPECIFICATION</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <div className="container">
        <div className="text-center mx-auto mb-sm-3 mb-3 wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="display-7 text-primary section-title1 mb-3"><b>WANT TO KNOW TECHNICAL DETAILS?</b></h4>
          <h6 className="display-7 fs-16">Explore Our Specification</h6>
        </div>

        <div className="row">
          <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
            <div className="table-responsive">
              <table className="table text-center border border-dark details-table table-fs14  table-sm table-bordered">
                <thead className="bg-primary text-white">
                  <tr>
                    <th className="bg-primary text-white font-weight-bold text-center">CHARACTERISTICS</th>
                    <th className="bg-primary text-white font-weight-bold text-center">REQUIREMENT AS PER ISO-13006/EN14411 GROUP Bla</th>
                    <th className="bg-primary text-white font-weight-bold text-center">OUR VALUES</th>
                    <th className="bg-primary text-white font-weight-bold text-center">ISO / EN TESTING METHOD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4} className="bg-primary text-white font-weight-bold text-center">DIMENSION AND SURFACE QUALITY</td>
                  </tr>
                  <tr>
                    <td>DEVIATION IN LENGTH & WIDTH</td>
                    <td>(+-0.5%)</td>
                    <td>(+-0.3%)</td>
                    <td>ISO-10545-2</td>
                  </tr>
                  <tr>
                    <td>DEVIATION IN THICKNESS</td>
                    <td>(+-5.0%)</td>
                    <td>(+-4.0%)</td>
                    <td>ISO-10545-2</td>
                  </tr>
                  <tr>
                    <td>STRAIGHTNESS IN SIDE</td>
                    <td>(+-0.5%)</td>
                    <td>(+-0.1%)</td>
                    <td>ISO-10545-2</td>
                  </tr>
                  <tr>
                    <td>RECTANGULARITY</td>
                    <td>(+-0.5%)</td>
                    <td>(+-0.1%)</td>
                    <td>ISO-10545-2</td>
                  </tr>
                  <tr>
                    <td>SURFACE FLATNESS</td>
                    <td>(+-0.5%)</td>
                    <td>(+-0.2%)</td>
                    <td>ISO-10545-2</td>
                  </tr>
                  <tr>
                    <td>GLOSSINESS</td>
                    <td>MIN 90%</td>
                    <td>PASS</td>
                    <td>GLOSSOMETER</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="bg-primary text-white font-weight-bold text-center">STRUCTURAL PROPERTIES</td>
                  </tr>
                  <tr>
                    <td>WATER ABSORPTION </td>
                    <td>&lt;0.50%</td>
                    <td>&lt;0.05%</td>
                    <td>ISO-10545-3</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="bg-primary text-white font-weight-bold text-center">MESSIVE MECHANICAL PROPERTIES</td>
                  </tr>
                  <tr>
                    <td>MODULUS OF REPTURE</td>
                    <td>MIN. 35N/mm2</td>
                    <td>MIN. 35N/mm2</td>
                    <td>ISO-10545-4</td>
                  </tr>
                  <tr>
                    <td>BREAKING STRENGTH</td>
                    <td>MIN. 1300N</td>
                    <td>MIN. 2000N</td>
                    <td>ISO-10545-4</td>
                  </tr>
                  <tr>
                    <td>IMPACT RESISTANCE</td>
                    <td>AS PER MFG.</td>
                    <td>MIN 0.55</td>
                    <td>ISO-10545-5</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="bg-primary text-white font-weight-bold text-center">SURFACE MECHANICAL PROPERTIES</td>
                  </tr>
                  <tr>
                    <td>SURFACE ABRASION RESISTANCE</td>
                    <td>MIN. CLASS -2</td>
                    <td>MIN. CLASS -3</td>
                    <td>ISO-10545-7</td>
                  </tr>
                  <tr>
                    <td>MOH'S HARDNESS</td>
                    <td>MIN. 5</td>
                    <td>MIN. 6</td>
                    <td>EN101</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="bg-primary text-white font-weight-bold text-center">CHEMICAL PROPERTIES</td>
                  </tr>

                  <tr>
                    <td>FROST RESISTANCE</td>
                    <td>REQUIERED</td>
                    <td>PASS</td>
                    <td>ISO-10545-12</td>
                  </tr>
                  <tr>
                    <td>HOUSEHOLD CHEMICALS RESISTANCE</td>
                    <td>MIN. CLASS AA</td>
                    <td>PASS</td>
                    <td>ISO-10545-13</td>
                  </tr>
                  <tr>
                    <td>STAIN RESISTANCE</td>
                    <td>RESISTANT</td>
                    <td>RESISTANT</td>
                    <td>ISO-10545-14</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="bg-primary text-white font-weight-bold text-center">THERMAL PROPERTIES</td>
                  </tr>
                  <tr>
                    <td>THERMAL SHOCK RESISTANCE</td>
                    <td>10 CYCLE </td>
                    <td>PASS</td>
                    <td>ISO-10545-9</td>
                  </tr>
                  <tr>
                    <td>THERMAL EXPANSION (COE)</td>
                    <td>MAX. 9.0 X 10.6 </td>
                    <td>MAX. 6.5 X 10.6</td>
                    <td>ISO-10545-8</td>
                  </tr>
                  <tr>
                    <td>MOISTURE EXPANTION </td>
                    <td>NILL</td>
                    <td>NILL</td>
                    <td>ISO-10545-10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
