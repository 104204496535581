import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layout.js";
import home1 from "../images/home1.jpg";
import home2 from "../images/home2.jpg";
import home3 from "../images/home3.jpg";
import teamwork from "../images/team-work.png";
import aboutImage from "../images/about-2.jpg";
import factIcon1 from "../images/icons/icon-2.png";
import factIcon2 from "../images/icons/icon-3.png";
import factIcon3 from "../images/icons/icon-4.png";
import bath from "../images/icons/bath.png";
import bed from "../images/icons/bed.png";
import home from "../images/icons/home.png";
import kitchen from "../images/icons/kitchen.png";
import mall from "../images/icons/mall.png";
import relax from "../images/icons/relax.png";
import coranoBlack from "../images/corano-black.jpg";
import chemicalResistant from "../images/chemical-resistant.png";
import durable from "../images/durable.png";
import easy from "../images/easy.png";
import ecoFriendly from "../images/eco-friendly.png";
import frostResistance from "../images/frost-resistance.png";
import zeroMaintenance from "../images/zero-maintenance.png";
import martiaGrey from "../images/martia-grey.jpg";
import glossy from "../images/glossy.jpg";
import highGlossy from "../images/high-glossy.jpg";
import matt from "../images/matt.jpg";
import carving from "../images/carving.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { seo } from "../seo.js";



export default function Home({ pageInfo }) {
 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName="Home">
      {/* Carousel Start  */}
      <div className="container-fluid p-0 pb-5 wow fadeInUp" data-wow-delay="0.1s">
        <OwlCarousel className="owl-carousel header-carousel position-relative" items={1} margin={0} autoplay={true} loop={true} dots={true} nav={false} dotData={true}>
          <div className="owl-carousel-item position-relative img-fluid">
            <div className="owl-dot" data-dot='<button role="button"><img alt="Slider Image" src={home1} /></button>'></div>
            <img className="img" alt="Slider Image"  src={home1} style={{ objectFit: "contain" }} />
          </div>
          <div className="owl-carousel-item position-relative img-fluid">
            <div className="owl-dot" data-dot='<img alt="Slider Image" src={home2} />'></div>
            <img className="img" alt="Slider Image" src={home2} style={{ objectFit: "contain" }}  />
          </div>
          <div className="owl-carousel-item position-relative img-fluid">
            <div className="owl-dot" data-dot="<img alt='Slider Image' src={home3} />"></div>
            <img className="img" alt="Slider Image" src={home3} style={{ objectFit: "contain" }} />
          </div>
        </OwlCarousel>
      </div>
      {/* Carousel End  */}
      {/* About Us Start  */}
      <div className="container-xxl py-sm-5 py-2">
        <div className="container">
          <div className="row g-5 img_up">
            <div className="col-lg-6 wow fadeIn about-img-col" data-wow-delay="0.1s">
              <div style={{ objectFit: "contain" }}>
                <img className="img-fluid wow fadeInLeft home-about-img" src={aboutImage} alt="About Us Image"/>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight about-text-col" data-wow-delay="0.5s">
              <h1 className="section-title1 mb-3"><b>ABOUT US</b></h1>
              <p>Velloza Granito LLP is an Indian Manufacturer and an exporter of Vitrified / Porcelain tiles With the capacity of 18000 SQMTR/DAY with the latest and innovative machinery.</p>
              <p>The promoters of Velloza Granito have a vast experience of more than 20 years in tiles manufacturing unit, which make company in front foot in quality and design development.</p>
              <p>With high end robust machine and latest technologies in its manufacturing process velloza has been successfully catering to the unique demands of the export sector.</p>
              <p className="mb-0">We value our customer's enquiry a lot more , we make it a point to ensure that our buyer gets the full value of their money once they place order with us.</p>
            </div>
          </div>
        </div>
      </div>
      {/* About Us End  */}
      {/* Facts Start  */}
      <div className="container-xxl py-sm-5 py-2 pb-4 homepage-factitems">
        <div className="container pt-5">
          <div className="row g-4 align-items-center justify-content-center facts-row">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={factIcon1} alt="Icon" />
                </div>
                <h4 className="mb-3 "><b>Global Marketing</b></h4>
                <p className="mb-0">Innovative marketing and excellent customer care epitomise our Global Mindset. We adopt global best standards and practices as our core competencies.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0 mt-2 mt-sm-0">
                <div className="fact-icon">
                  <img src={teamwork} alt="Icon" className="primary-filter" />
                </div>
                <h4 className="mb-3"><b>Team Work</b></h4>
                <p className="mb-0">We support each other across all organisational and geographical lines so that our shaped goals and objectives can be achieved efficiently.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={factIcon3} alt="Icon" />
                </div>
                <h4 className="mb-3"><b>Learn & Innovative</b></h4>
                <p className="mb-0">In order to achieve long term success in the competition, we must constantly learn and innovate to deliver higher added products and services to our customers.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={factIcon2} alt="Icon" />
                </div>
                <h4 className="mb-3"><b>Service Excellence</b></h4>
                <p className="mb-0">We are passionate in providing our customers excellent services and solutions by taking ownership and stretching our boundaries.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facts End */}
      <section className="concept">
        <div className="row">
          <div className="col-lg-10 col-sm-12 col-xs-12 wow fadeInUp concept-bg">
            <div className="row text-center justify-content-center">
              <div className="offset-lg-3 col-lg-6">
                <h5 className="section-title1"><b>OUR CONCEPT</b></h5>
                <p style={{ fontSize: "18px" }}>To create value & build better lifestyle for our customers by providing world-class ceramic products with superior quality.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="row">
          <div className="offset-lg-2 offset-sm-0 col-lg-10 col-sm-12 col-xs-10 wow fadeInUp no-padding concept-details">
            <div className="row center_part">
              <div className="col-lg-6 col-sm-12 col-xs-12 padding-0-col">
                <div className="tital sm-text-center home_floor_sec">
                  <h5 className="h3_tag"><b>Explore Our Tiles Application</b></h5>
                  <p>We offer you premium tiles with unmatched quality and aesthetics, made of using state-of-the-art technology.</p>
                </div>
                <div className="row row-eq-height ">
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={bath} alt="Bathroom Tiles" className="img-fluid"/>
                      <h6 className="text-dark">Bathroom Tiles</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={kitchen} alt="Kitchen Tiles" className="img-fluid"/>
                      <h6 className="text-dark">Kitchen Tiles</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={relax} alt="Living Room Tiles" className="img-fluid"/>
                      <h6 className="text-dark">Living Room Tiles</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={home} alt="Outdoor Tiles" className="img-fluid"/>
                      <h6 className="text-dark">Outdoor Tiles</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={bed} alt="Bedroom Tiles" className="img-fluid"/>
                      <h6 className="text-dark">Bedroom Tiles</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={mall} alt="Tiles For Commercial Spaces" className="img-fluid"/>
                      <h6 className="text-dark">Tiles For Commercial Spaces</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-xs-12 eot_img">
                <img decoding="async" src={coranoBlack} alt="Explore Our Tiles Application" className="img-fluid sm-w-100"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <section className="concept-floor">
        <div className="row">
          <div className=" col-lg-10 col-sm-12 col-xs-10 wow fadeInUp no-padding concept-details">
            <div className="row center_part">
              <div className="col-lg-6 col-sm-12 col-xs-12 eot_img">
                <img decoding="async"  src={martiaGrey}  alt="Explore Our Tiles Features"  className="img-fluid sm-w-100"/>
              </div>
              <div className="col-lg-6 col-sm-12 col-xs-12">
                <div className="tital sm-text-center home_floor_sec py-2">
                  <h5 className="h3_tag"><b>Explore Our Tiles Features</b></h5>
                  <p>We Offer You Premium Tiles With Unmatched Quality And Aesthetics, Made Of Using State-of-the-art Technology.</p>
                </div>
                <div className="row row-eq-height">
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={chemicalResistant} alt="Chemical Resistant" className="img-fluid"/>
                      <h6 className="text-dark">Chemical Resistant</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={durable} alt="Durable" className="img-fluid"/>
                      <h6 className="text-dark">Durable</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={easy} alt="Easy to Clean" className="img-fluid"/>
                      <h6 className="text-dark">Easy to Clean</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={ecoFriendly} alt="Eco Friendly" className="img-fluid"/>
                      <h6 className="text-dark">Eco Friendly</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={frostResistance} alt="Frost Resistance" className="img-fluid"/>
                      <h6 className="text-dark">Frost Resistance </h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-xs-12 no-padding">
                    <div className="tiles-type">
                      <img decoding="async" src={zeroMaintenance} alt="Zero Maintenance" className="img-fluid"/>
                      <h6 className="text-dark">Zero Maintenance</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Project Start */}
      <div className="container-xxl project py-sm-5 py-2 pt-5">
        <div className="container">
          <div className="text-center mx-auto mb-sm-5 mb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
            <h5 className="section-title1"><b>OUR PROJECTS</b></h5>
            <h5 className="display-7 mb-4 sub-sec-title">Visit Our Latest Projects And Our Innovative Works</h5>
          </div>
          <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
            <div className="col-lg-4">
              <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                <button className="nav-link w-100 d-flex align-items-center text-start p-sm-4 p-3 mb-sm-4 mb-3 active rounded-0" data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button"><h5 className="m-0">01.Glossy</h5></button>
                <button className="nav-link w-100 d-flex align-items-center text-start p-sm-4 p-3 mb-sm-4 mb-3 rounded-0" data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button"><h5 className="m-0">02.High Glossy</h5></button>
                <button className="nav-link w-100 d-flex align-items-center text-start p-sm-4 p-3 mb-sm-4 mb-3 rounded-0" data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button"><h5 className="m-0">03.Matt</h5></button>
                <button className="nav-link w-100 d-flex align-items-center text-start p-sm-4 p-3 mb-sm-4 mb-0 rounded-0" data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button"><h5 className="m-0">04.Carving </h5></button>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content w-100">
                <div className="tab-pane fade show active" id="tab-pane-1">
                  <div className="row g-4">
                    <div className="col-md-6" style={{ minHeight: "390px" }}>
                      <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={glossy} style={{ objectFit: "cover" }} alt="glossy"/>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center" style={{ fontWeight: "400" }}>
                      <p className="mb-0">
                        The ceramic era inspired the idea of the glossy collection, which combines visual complexity the quality of the aged ornamental & the linear severity of the geometric deacons.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tab-pane-2">
                  <div className="row g-4">
                    <div className="col-md-6" style={{ minHeight: "390px" }}>
                      <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={highGlossy} style={{ objectFit: "cover" }} alt="highGlossy"/>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center" style={{ fontWeight: "400" }}>
                      <p className="mb-0">
                      Our hi-glossy tiles collection recreates the perfect synthesis between architecture & nature. It comes in a range of shades to fit different contemporizes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tab-pane-3">
                  <div className="row g-4">
                    <div className="col-md-6" style={{ minHeight: "390px" }}>
                      <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={matt} style={{ objectFit: "cover" }} alt="matt"/>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center" style={{ fontWeight: "400" }}>
                      <p className="mb-0">
                        Rejuvenate your auras, feel the sensation & experience the gesture of serenity with our soothing finishes. Each finish has the vibes to land you on cloud nine.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="tab-pane-4">
                  <div className="row g-4">
                    <div className="col-md-6" style={{ minHeight: "390px" }}>
                      <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={carving} style={{ objectFit: "cover" }} alt="carving"/>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center" style={{ fontWeight: "400" }}>
                      <p className="mb-0">
                        The carving effect adds a sense of illusion to a space by providing depth with the shiny crevices like patterns. This effect is acquired by digitally printing on matt surfaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Project End */}
    </Layout>
  );
}
