import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import Header from './layout/header';
import Footer from './layout/footer';
import $ from 'jquery';
import axios from "axios";
import * as Constants from "../constants";
import {Helmet} from "react-helmet";
import { seo } from "../seo.js";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


export default function Layout({ children , pageName }) {
  
  const [headerInfo, setHeaderInfo] = useState([]);
  const [settingInfo, setSettingInfo] = useState([]);

  const client = axios.create({
	    baseURL: Constants.API_BASE_URL,
  });
  
  useEffect(() => {
	    client.get("getHeaderMenu").then((response) => {
	      setHeaderInfo(response.data.data.headerInfo);
	      setSettingInfo(response.data.data.settingInfo);
	     
	    }).catch(error => {
	        console.error('POST Request Error:', error);
	        // You can handle errors here
		});
	   
	    
  }, []);
  
  useEffect(() => {
	    seo({
	      title: pageName + ' | ' +  settingInfo.v_site_title ,
	    });
  }, [settingInfo]);
  
 return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name='title' content={(pageName !== null && pageName !== "" ? pageName : 'Home' ) + ' | ' + (settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : "")} />
      <meta name='description' content={settingInfo.v_site_description !== null && settingInfo.v_site_description !== "" ? settingInfo.v_site_description : ""} />
      <meta name='keywords' content={settingInfo.v_site_keywords !== null && settingInfo.v_site_keywords !== "" ? settingInfo.v_site_keywords : ""} />
      <meta name='author' content={settingInfo.v_meta_author !== null && settingInfo.v_meta_author !== "" ? settingInfo.v_meta_author : ""} />
      <meta name='copyright' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
      <meta name='publisher' content={settingInfo.v_meta_author !== null && settingInfo.v_meta_author !== "" ? settingInfo.v_meta_author : ""} />
      
      <meta property='og:title' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
      <meta property='og:description' content={settingInfo.v_site_description !== null && settingInfo.v_site_description !== "" ? settingInfo.v_site_description : ""} />
      <meta property="og:url" content={Constants.SITE_URL} />
      <meta property='og:site_name' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
      {settingInfo.v_website_og_icon !== null && settingInfo.v_website_og_icon !== "" && (
        <meta property='og:image' content={Constants.BACKEND_FILE_URL + settingInfo.v_website_og_icon} />
      )}

      <meta property="twitter:url" content={Constants.SITE_URL} />
      <meta property='twitter:title' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
      <meta property='twitter:description' content={settingInfo.v_site_description !== null && settingInfo.v_site_description !== "" ? settingInfo.v_site_description : ""} />
      {settingInfo.v_website_logo !== null && settingInfo.v_website_logo !== "" && (
        <meta property='twitter:image' content={Constants.BACKEND_FILE_URL + settingInfo.v_website_logo} />
      )}

    	<link rel="canonical" href={Constants.SITE_URL} />
      {settingInfo.v_website_fav_icon !== null && settingInfo.v_website_fav_icon !== "" && (
        <link rel="icon" href={Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon} />
      )}
      {settingInfo.v_website_fav_icon !== null && settingInfo.v_website_fav_icon !== "" && (
        <link rel="apple-touch-icon" href={Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon} />
      )}
      
     </Helmet>		
      <Header headerInfo={headerInfo} settingInfo={settingInfo}/>
      <main  >{children}</main>
      <Footer settingInfo={settingInfo} />
    </>
  )
}