import React, { useEffect , useState} from "react";
import { seo } from "../seo.js";
import { Form } from "react-router-dom";
import Layout from "./layout.js";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import axios from "axios";

export default function Download({ pageInfo }) {
  const client = axios.create({
    baseURL: Constants.API_BASE_URL 
  });

  var brochure_details_route = 'getBrochureDetails/'
  const [brochureData, setBrochureData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
		client.get(brochure_details_route).then((response) => {
			setBrochureData(response.data.data.brochureDetails);
		})
	}, []);

  const multiDimensionalArray = Object.values(brochureData);

  return (
    <Layout pageName="Download">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-download py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Download</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">Download</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <section className="download-section">
        <div className="container">
          <div className="text-center">
            <h3 className="section-title1 heading"><b>OUR DOWNLOAD SECTION</b></h3>
            <span className="heading-top-text position-relative posi h5 font-weight-boldtion-relative text-left">Want To Explore?</span>
          </div>
          { multiDimensionalArray.length > 0 ?  multiDimensionalArray.map((row, rowIndex) => (
          <div className="row pt-4 justify-content-center" key={rowIndex}>
            {row.map((value, colIndex) => (
            
            <div className="col-lg-3 mb-4 col-md-4" key={colIndex}>
              <div className="download-card shadow text-center">
                <a href={Constants.BACKEND_FILE_URL + value.v_brochure_file} target="_blank" className="link-unstyled d-inline-block w-100">
                  <div className="card border-0 shadow-sm download-card-footers">
                    <div className="team-main-image">
                      <img src={Constants.BACKEND_FILE_URL + value.v_brochure_image} alt="Download Section Image" className="card-img-top team-image rounded-0"/>
                    </div>
                    <div className="card-body bg-light  py-3">
                      <span className="font-weight-bold d-block mb-0 h6 text-dark designation-heading">{value.v_brochure_name}</span>
                    </div>
                  </div>
                </a>
                <div className="d-flex main-card-footer justify-content-between bg-white">
                  <a href={Constants.BACKEND_FILE_URL + value.v_brochure_file} target="_blank" className="dwn_btn1  py-2 pl-2 w-50 border border-dark" download >Download</a>
                  <a href={Constants.BACKEND_FILE_URL + value.v_brochure_file} target="_blank" className="dwn_btn1 py-2 pr-2 w-50 border border-dark">View</a>
                </div>
              </div>
            </div> 
            ))}
          </div>
          ))  :<div className="container mt-4">
					<div class="card product-not-found-card card-body shadow">
						<h3 class="mb-0 product-not-found-text text-uppercase">Coming Soon</h3>
					</div>
				</div> }
        </div>
      </section>
    </Layout>
  );
}
