import React, { useEffect } from "react";
import Layout from "./layout.js";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import certificate1 from "../images/iso-14001-2015.png";
import certificate2 from "../images/declaration-of-performance.png";
import certificate3 from "../images/iso-9001-2015.png";
import certificate4 from "../images/one-star-status.png";
import { seo } from "../seo.js";
import Fancybox from "../js/fancybox.js";

export default function Certificate({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName="Certificate">
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-company py-5 mb-sm-5 mb-4 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Certificate</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item"><span className="text-white">Corporate</span></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">Certificate</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}

      <div className="photo-gallery">
        <div className="container">
          <div className="text-center mx-auto mb-sm-5 mb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
            <h4 className="section-title1"><b>TRUST</b></h4>
            <h5>Certificates</h5>
          </div>
          <Fancybox options={{Carousel: {infinite: false,},}}>
            <div className="row photos justify-content-center mx-auto mb-8 wow fadeInUp" data-wow-delay="0.1s">
              <div className="col-sm-6 col-md-4 col-lg-3 item">
                <a href={certificate1} data-fancybox="photos" className="h-100"><img alt="certificate" className="img_tiles img-fluid w-100 h-100" src={certificate1}/></a>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 item">
                <a href={certificate2} data-fancybox="photos" className="h-100"><img alt="certificate" className="img_tiles img-fluid w-100 h-100" src={certificate2}/></a>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 item">
                <a href={certificate3} data-fancybox="photos" className="h-100"><img alt="certificate" className="img_tiles img-fluid w-100 h-100" src={certificate3} /></a>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 item">
                <a href={certificate4} data-fancybox="photos" className="h-100"><img alt="certificate" className="img_tiles img-fluid w-100 h-100" src={certificate4}/></a>
              </div>
            </div>
          </Fancybox>
        </div>
      </div>
    </Layout>
  );
}
