import React, { useRef, useEffect , useState } from "react";
import { seo } from "../seo.js";
import Layout from "./layout.js";
//import "@flaticon/flaticon-uicons/css/all/all.css";
import mail_img from "../images/mail.svg";
import phone_img from "../images/phone.svg";
import location_img from "../images/location.svg";
import { useForm } from "react-hook-form";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import axios from "axios";
import {decode} from 'html-entities';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact({ pageInfo }) {
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  
  const client = axios.create({
	    baseURL: Constants.API_BASE_URL,
	});
  
  useEffect(() => {
    client.get("getHeaderMenu").then((response) => {
      setSettingInfo(response.data.data.settingInfo);
    });
  }, []);
  const [settingInfo, setSettingInfo] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("loader-showing");
    } else {
      document.body.classList.remove("loader-showing");
    }
  }, [isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
	setIsLoading(true);
    const headers = {
    	'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    	"Access-Control-Allow-Origin": "*",
    }
    client.post("submitContact",data, {
        headers: headers
    }).then((response) => {
      // Handle the response from the server if needed
     
      if( response.data.status_code == 1 ){
    	setIsLoading(false);
    	toast.success( response.data.message );
        reset();
      } else {
    	  toast.error( response.data.message );
      }
    })
    .catch((error) => {
      // Handle errors if the request fails
    	toast.error( error ); 
      console.error(error);
    });
  };

  let rendorComplete = settingInfo.i_id;

  return (
    <Layout pageName="Contact Us">
        {isLoading && (
        <div className="loader">
          <div className="loader--ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {/* Page Header Start  */}
      <div>
        <div className="container-fluid page-header-contact py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }}>
          <div className="container py-5">
            <h1 className="display-5 text-white animated slideInDown">Contact Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} className="text-white">Home</Link></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">CONTACT US</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End  */}
      <div className="container-xxl">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
            <h5 className="section-title1"><b>CONTACT US</b></h5>
            <h5 className="display-8 mb-4 fs-16">If You Have Any Query, Please Feel Free Contact Us</h5>
          </div>
          <div className="row contect-box-row">
            <div className="col-lg-5 contect-box-div">
              <div className="contect-box">
                <div className="info-icon-mdiv">
                  <img className="info-icon" src={location_img} alt="Info Icon"/>
                </div>
                {settingInfo.v_address !== null && settingInfo.v_address !== "" && (
                  <div className="info-contant">
                    <h6 className="info-contant-title">Address</h6>
                    <p className="info-contant-detail" dangerouslySetInnerHTML={{__html: decode(settingInfo.v_address)}}></p>
                  </div>
                )}
              </div>
            </div>
            
            <div className="col-lg-4 contect-box-div">
            {settingInfo.v_email !== null && settingInfo.v_email !== "" &&(
              <a href={"mailto:" + settingInfo.v_email} className="info-contant-link contect-box">
                <div className="info-icon-mdiv">
                  <img className="info-icon" src={mail_img} alt="Info Icon" />
                </div>
                <div className="info-contant">
                  <h6 className="info-contant-title">Email</h6>
                  <p className="info-contant-detail">{settingInfo.v_email}</p>
                </div>
              </a>
            )}
            </div>
            <div className="col-lg-3 contect-box-div">
            {settingInfo.v_primary_mobile_no !== null && settingInfo.v_primary_mobile_no !== "" && rendorComplete && (
              <a href={"tel:" + settingInfo.v_primary_mobile_no.replace(/ /g,'')} className="info-contant-link contect-box">
                <div className="info-icon-mdiv">
                  <img className="info-icon" src={phone_img} alt="Info Icon" />
                </div>
                <div className="info-contant">
                  <h6 className="info-contant-title">Contact No.</h6>
                  <p className="info-contant-detail">{settingInfo.v_primary_mobile_no}</p>
                </div>
              </a>
            )}
            </div>
          </div>
          
          <div className="row g-5 mt-2">
            <div className="col-lg-6 wow fadeInUp padding-0-col" data-wow-delay="0.1s">
              {settingInfo.v_google_map !== null && settingInfo.v_google_map !== "" && (
                <div className="d-flex flex-column justify-content-between h-100">
                  <iframe className="w-100 mb-n2 contact-iframe" style={{ height: "450px" }} src={settingInfo.v_google_map} width="800" height="600" loading="lazy"></iframe>
                </div>
              )}
            </div>
            <div className="col-lg-6 wow fadeInUp d-flex justify-content-center align-items-center padding-0-col" data-wow-delay="0.5s">
              <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" name="name" placeholder="Your Name" {...register("name", { required: true , setValueAs: value => value.trim() })}/>
                      <label htmlFor="name">Your Name<span className="text-danger">*</span></label>
                    </div>
                    {errors.name && (<span className="text-danger">Please Enter Your Name.</span>)}
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" name="email" placeholder="Your Email" {...register("email", {required: "Please Enter Your Email.",pattern: {value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Please Enter Valid Email.",},})}/>
                      <label htmlFor="email">Your Email<span className="text-danger">*</span></label>
                    </div>
                    {errors.email && (<span className="text-danger">{errors.email?.message}</span>)}
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" name="subject" placeholder="Subject" {...register("subject", { required: true , setValueAs: value => value.trim() })}/>
                      <label htmlFor="subject">Subject<span className="text-danger">*</span></label>
                    </div>
                    {errors.subject && (<span className="text-danger">Please Enter Subject.</span>)}
                  </div>
                  <div className="col-12 d-none">
	                  <div className="form-floating">
	                    <input type="text" className="form-control" name="webname" placeholder="WebName" {...register("webname", { required: false })}/>
	                    <label htmlFor="webname">WebName<span className="text-danger">*</span></label>
	                  </div>
	                  {errors.webname && (<span className="text-danger">Please Enter WebName.</span>)}
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control" placeholder="Leave a message here" name="message" style={{ height: "100px" }} {...register("message", { required: false })}></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3 rounded-0" type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
