import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { NavLink, Link   } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Popper from "popper.js";
import "../../js/custom";
import * as Constants from "../../constants";
import "../../js/custom";
import WOW from "wowjs";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { useMediaQuery } from 'react-responsive'
import axios from "axios";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const handleDropdownClick = (event) => {
  event.stopPropagation();
};


function MenuItem({ item }) {

  const isMobile = useMediaQuery({ query: '(max-width:991px)' });
  function removeHrefAddDropdownToggle(dropdownItem) {
    if (dropdownItem) {
      dropdownItem.removeAttribute("href");
      dropdownItem.setAttribute("data-bs-toggle", "dropdown");
    }
  }

  useEffect(() => {
    if (isMobile) {
      const submenuDivs = document.querySelectorAll(".dropdown-menu .submenu");

      submenuDivs.forEach((submenuDiv) => {
        const parentDropdownItem = submenuDiv.parentNode; 
        const parentDropdownItem2 = parentDropdownItem.firstChild; 
        removeHrefAddDropdownToggle(parentDropdownItem2);
      });
    }
  }, [isMobile]);

	const navigate = useNavigate();
	return (
    <li>
      <Link to={item.slug} data-link={item.slug} className="dropdown-item" onClick={handleDropdownClick}>
        {item.label}
        {item.children !== null &&
          item.children !== "" &&
          item.children.length > 0 && (
            <strong className="float-end"><i className="fi fi-rr-caret-right"></i></strong>
          )}
      </Link>
      {item.children !== null &&
        item.children !== "" &&
        item.children.length > 0 && (
          <ul className="submenu dropdown-menu">
            {item.children.map((child) => (
              <MenuItem key={child.label} item={child} />
            ))}
          </ul>
        )}
    </li>
  );
}

export default function Header({ headerInfo, settingInfo }) {
 let rendorComplete = settingInfo.i_id;
  // wowjs animation
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
    wow.start();
  }, []);

  var spinner = function () {
    setTimeout(function () {
      if ($("#spinner").length > 0) {
        $("#spinner").removeClass("show");
      }
    }, 1);
  };
  spinner();

  // Google Translate
  const ref = useRef(false);
  useEffect(() => {
    if (!ref.current) {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      ref.current = true;
    }
  }, []);

  window.onload = function () {
    //googleTranslateElementInit();
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
      },
      "google_translate_element"
    );

    var $googleDiv = $("#google_translate_element .skiptranslate");
    var $googleDivChild = $("#google_translate_element .skiptranslate div");
    $googleDivChild.next().remove();

    $googleDiv
      .contents()
      .filter(function () {
        return this.nodeType === 3 && $.trim(this.nodeValue) !== "";
      })
      .remove();
  };

  //const menuData1 = [{"label":"GVT","children":[{"label":"600*600","children":[{"label":"Glossy","children":[]},{"label":"Matt","children":[]}]},{"label":"600*1200","children":[{"label":"glossy","children":[{"label":"TV","children":[]}]}]}]},{"label":"ELectronic","children":[{"label":"TV","children":[]},{"label":"Washing Machine","children":[]}]}];
  //const menuData = [{"label":"GVT","children":[{"label":"600*600","children":[{"label":"Glossy","children":[]},{"label":"Matt","children":[]}]},{"label":"600*1200","children":[{"label":"glossy","children":[{"label":"glossy","children":[]},{"label":"book matcrh","children":[]}]}]}]},{"label":"ELectronic","children":[{"label":"TV","children":[{"label":"Samsung","children":[]},{"label":"LG","children":[]}]},{"label":"Washing Machine","children":[]}]}];
  const menuData = headerInfo;
  //[{"label":"GVT","children":[[{"label":"600*600","children":[[{"label":"Glossy","children":[]},{"label":"Matt","children":[]}]]},{"label":"600*1200","children":[[{"label":"glossy","children":[[{"label":"glossy","children":[]},{"label":"book matcrh","children":[]}]]}]]}]]},{"label":"ELectronic","children":[[{"label":"TV","children":[]},{"label":"Washing Machine","children":[]}]]}]

  
  
  if( settingInfo !== null && settingInfo !== null && settingInfo.v_website_fav_icon !== null && settingInfo.v_website_fav_icon !== "" ){
	  const fav_icon = document.querySelector('link[rel="icon"]');
	  const apple_touch_icon = document.querySelector('link[rel="apple-touch-icon"]');

	  if (fav_icon ) {
		  fav_icon.setAttribute('href', settingInfo.v_website_fav_icon);
	  } 
	  if (apple_touch_icon ) {
		  apple_touch_icon.setAttribute('href', settingInfo.v_website_fav_icon);
	  } 
  }
  
  return (
    <header>
      {/* Topbar Start */}
      <div className="container-fluid bg-dark p-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="row gx-0 d-none d-lg-flex nav-top">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-3">
              {settingInfo.v_primary_mobile_no !== null &&
                settingInfo.v_primary_mobile_no !== "" &&
                rendorComplete && (
                  <a className="text-light px-2 d-flex align-items-center" href={"tel:" + settingInfo.v_primary_mobile_no.replace(/ /g,'')}>
                    <i className="fa fa-phone text-primary me-2" style={{ fontSize: "22px" }}></i>
                    {settingInfo.v_primary_mobile_no}
                  </a>
                )}
              {settingInfo.v_email !== null &&
                settingInfo.v_email !== "" && (
                  <a className="text-light px-2" href={"mailto:" + settingInfo.v_email}>
                    <i className="fa fa-envelope text-primary me-2"></i>
                    {settingInfo.v_email}
                  </a>
                )}
            </div>
          </div>
            <div className="col-lg-5 px-5 text-end">
              <div className="h-100 d-inline-flex align-items-center py-3 me-2"><a className="text-light px-2" href="">Follow Us</a></div>
              <div className="h-100 d-inline-flex align-items-center">
                {settingInfo.v_facebook_link !== null && (
                  <a className="btn btn-square rounded-0 btn-outline-body me-1" target="_blank" href={settingInfo.v_facebook_link}><i className="fa fa-facebook-f"></i></a>
                )}
                {settingInfo.v_instagram_link !== null && (
                  <a className="btn btn-square rounded-0 btn-outline-body me-1" target="_blank" href={settingInfo.v_instagram_link}><i className="fa fa-instagram"></i></a>
                )}
                {settingInfo.v_linkedin_link !== null && (
                  <a className="btn btn-square rounded-0 btn-outline-body me-1" target="_blank" href={settingInfo.v_linkedin_link} ><i className="fa fa-linkedin"></i></a>
                )}
                {settingInfo.v_youtube_link !== null && (
                  <a className="btn btn-square rounded-0 btn-outline-body me-1" target="_blank" href={settingInfo.v_youtube_link}><i className="fi fi-brands-youtube d-flex"></i></a>
                )}
                {settingInfo.v_twitter_link !== null && (
                  <a className="btn btn-square rounded-0 btn-outline-body me-0" target="_blank" href={settingInfo.v_twitter_link}><i className="fi fi-brands-twitter d-flex"></i></a>
                )}
              </div>
            </div>
        </div>
      </div>
      {/* Topbar End  */}

      {/* Navbar Start  */}
      {/* Menu  */}
      <div className="bg-primary twt-navbar ">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark p-0">
            {settingInfo.v_website_logo != null && settingInfo.v_website_logo != "" && (
              <div><Link to={Constants.HOME_PAGE_URL}><img src={Constants.BACKEND_FILE_URL + settingInfo.v_website_logo} alt={settingInfo.v_site_title} className="img-fluid main-header-logo" /></Link></div>
            )}
            <button className="navbar-toggler my-2 rounded-0 ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center lh-lg" id="main_nav">
              <ul className="navbar-nav p-3 p-md-0">
                <li className="nav-item "><Link className=" nav-link active" to={Constants.HOME_PAGE_URL}>Home</Link></li>
                <li className="nav-item dropdown">
                  <a className="nav-link active dropdown-toggle" href="#" data-bs-toggle="dropdown">Corporate</a>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to={Constants.COMPANY_PROFILE_PAGE_URL}>Company Profile</Link></li>
                    <li><Link className="dropdown-item" to={Constants.CERTIFICATE_PAGE_URL}>Certificate</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link active dropdown-toggle" href="#" data-bs-toggle="dropdown">Export</a>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to={Constants.PACKING_LIST_PAGE_URL}>Packing List</Link></li>
                    <li><Link className="dropdown-item" to={Constants.TECHNICAL_SPECIFICATION_PAGE_URL}>Technical Specification</Link></li>
                    <li><Link className="dropdown-item" to={Constants.SERVICE_PAGE_URL}>Service</Link></li>
                    <li><Link className="dropdown-item" to={Constants.WHERE_WE_EXPORT_PAGE_URL}>Where We Export</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link active dropdown-toggle" href="#" data-bs-toggle="dropdown">Products</a>
                  <ul className="dropdown-menu products-dropdown-menu submenu">
                    {menuData.map((item) => (
                      <MenuItem key={item.label} item={item} />
                    ))}
                  </ul>
                </li>
                <li className="nav-item"><Link className="nav-link active" to={Constants.TILES_CALCULATOR_PAGE_URL}>Tiles Calculator</Link></li>
                <li className="nav-item"><Link className="nav-link active" to={Constants.DOWNLOAD_PAGE_URL}>Download</Link></li>
                <li className="nav-item"><Link className="nav-link active" to={Constants.CONTACT_US_PAGE_URL}>Contact Us</Link></li>
              </ul>
            </div>
            <div id="google_translate_element" className="google-translate"></div>
          </nav>
        </div>
      </div>
    </header>
  );
}
