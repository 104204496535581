import React, { useEffect, useRef , useState } from "react";
import { useParams , Link } from 'react-router-dom';
import $ from "jquery";
import { Form } from "react-router-dom";
import Layout from "./layout.js";
import axios from "axios";
import * as Constants from "../constants";
import { seo } from "../seo.js";
import Fancybox from "../js/fancybox.js";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


export default function CategoryInfo({ pageInfo }) {
	
	const client = axios.create({
		  baseURL: Constants.API_BASE_URL 
	});
	
	const { category_slug } = useParams();
	
	var category_info_route = 'product_category/'  +  category_slug
	const [categoryProduct, setCategoryProduct] = useState([]);
	const [slugInfo, setSlugInfo] = useState([]);
	const [listedPageTitle, setListedPageTitle] = useState([]);
	const [settingInfo, setSettingInfo] = useState([]);
	
	
	const [pageTitle, setPageTitle] = useState('Category');

	useEffect(() => {
		client.get(category_info_route).then((response) => {
			setCategoryProduct(response.data.data.productDetails);
			setSlugInfo(response.data.data.allParentMenuDetails);
			setSettingInfo(response.data.data.settingInfo);
			setListedPageTitle(response.data.data.allParentMenuDetails[response.data.data.allParentMenuDetails.length - 1]);
			const atag = document.getElementsByClassName('navbar-toggler');
			for (const element of atag) {
				const collapseAttribute = element.getAttribute('aria-expanded');
				if (window.innerWidth < 992 && collapseAttribute == 'true') {
				  $(".navbar-toggler").click();
				}
			}
			if( response.data.data.allParentMenuDetails[0] !== null && response.data.data.allParentMenuDetails[0] !== "" ){
				/*setPageTitle(response.data.data.allParentMenuDetails[0].v_category_name  )
				seo({
					title: response.data.data.allParentMenuDetails[0].v_category_name 
				});*/
			}
			window.scrollTo(0, 0);
		}).catch(error => {
	        console.error('POST Request Error:', error);
	        // You can handle errors here
		});
	}, [category_slug]);
	 useEffect(() => {
		    window.scrollTo(0, 0);
		  }, []);
	
	var listed_category_name =  ( listedPageTitle.v_category_name !== null && listedPageTitle.v_category_name !== "" ? listedPageTitle.v_category_name : "" )  ;
	var documentTitle = listed_category_name + ' | ' +  settingInfo.v_site_title ;
	seo({
		title: documentTitle
	});
  const multiDimensionalArray = Object.values(categoryProduct);
  //console.log("multiDimensionalArray");
  //console.log(multiDimensionalArray);
  return (
		 
		  <Layout pageName={listed_category_name}>
			  <div>
		        <div className="container-fluid page-header py-sm-5 py-4 mb-sm-5 mb-3 wow fadeIn" data-wow-delay="0.1s" style={{ height: "55vh" }} >
		          <div className="container py-5">
		            <h1 className="display-5 text-white animated slideInDown">
		              { slugInfo.length > 0 ?  slugInfo[0].v_category_name :  'Category' }
		            </h1>
		            <nav aria-label="breadcrumb animated slideInDown">
		              <ol className="breadcrumb text-uppercase mb-0">
		                <li className="breadcrumb-item">
		                  <Link className="text-white" to={Constants.HOME_PAGE_URL}>Home</Link>
		                </li>
		                <li className="breadcrumb-item">
		                  <a className="text-white" href={void(0)}>Products</a>
		                </li>
		                {slugInfo.map((value, colIndex) => (
		                <li className="breadcrumb-item" key={'category-' + colIndex}>
		                { colIndex === ( slugInfo.length - 1 ) ?  
		                		( <Link className="text-white text-primary active"  to={'/category/' +  value.v_category_slug + '.html' }>{value.v_category_name }</Link> ) : 
		                		( <Link className="text-white"  to={ '/category/' +  value.v_category_slug + '.html' }>{value.v_category_name }</Link> )
		                }
		                </li>
		                ))}
		              </ol>
		            </nav>
		          </div>
		        </div>
		      </div>
			  <div className="main-photo-gallery">
			  	{ multiDimensionalArray.length > 0 ?  multiDimensionalArray.map((row, rowIndex) => (
			  		<div className="photo-gallery" key={'category-product-' + rowIndex}>
			  			<div className="container justify-content-center">
			  				<div className="text-center mx-auto mb-3 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }} >
				  				<h3 className="display-8 mb-4 section-title1"> 
				  					<b><Link to={ '/category/' + row[0].v_category_slug + '.html' }  >{ row[0].v_category_name } { ( row[0].v_parent_category_name !== null  && row[0].v_parent_category_name !== "" ) ? ' ( ' +  row[0].v_parent_category_name + ' )' : '' }</Link></b>
				  				</h3>
				  			</div>
							<Fancybox options={{Carousel: {infinite: false,},}}>
				  			<div className="row photos justify-content-center mx-auto mb-8 wow fadeInUp" data-wow-delay="0.1s" >
				  			{row.map((value, colIndex) => (
				  				<div key={ rowIndex + '_' + colIndex} className="col-sm-6 col-md-4 col-lg-2 item">
				                <br />
				                <a href={Constants.BACKEND_FILE_URL + value.v_product_main_image } data-fancybox="photos">
				                  <img className="img_tiles img-fluid products-imgs" src={Constants.BACKEND_FILE_URL + value.v_product_main_image } alt={value.v_product_name} />
				                </a>
				                <h6 className="product-name text-center mb-0">{value.v_product_name}</h6>
				           </div>
				  			))}
				  		</div>
						</Fancybox>
				  	</div>
				 </div>
				        
			  )) :<div className="container">
					<div class="card product-not-found-card card-body shadow">
						<h3 class="mb-0 product-not-found-text text-uppercase">Coming Soon</h3>
					</div>
				</div>}
		</div>
	</Layout>
  	)
 }